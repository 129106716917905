
.ribbon-1 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #def7a8;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  width: 42%;
  height: 6%;
  text-align: center;
  padding-top: 8px;
  font-weight: 600;
  color: black;
}

.ribbon-2 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #f399b9;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  width: 42%;
  height: 6%;
  text-align: center;
  padding-top: 8px;
  font-weight: 600;
  margin-top: 15%;
  color: black;
}

.ribbon-3 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #8ff1f5;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  width: 42%;
  height: 6%;
  text-align: center;
  padding-top: 8px;
  font-weight: 600;
  margin-top: 30%;
  color: black;
}

